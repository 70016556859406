
.texteditor-container {
  border: 1px solid #ddd;
  padding: 1em;
}

.texteditor-container.readonly {
  border: none;
  /* margin-top: 1em; */
  padding: 0;
}

.texteditor-container.answer-analytic-text {
  margin-top: 2em;
}

.DraftEditor-root {
  background: transparent;
}
.DraftEditor-root img{
  width: 100%;
}

.DraftEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.DraftEditor-editor .public-DraftEditorPlaceholder-root,
.DraftEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.DraftEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.DraftEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.DraftEditor-editor .DraftEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.DraftEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.DraftEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.DraftEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.DraftEditor-activeButton {
  color: #5890ff;
}

.public-DraftEditorPlaceholder-root {
  margin-bottom: -25px;
  color: #9e9e9e;
}

.DraftEditor-alignLeft {
  text-align: left;
}

.DraftEditor-alignCenter {
  text-align: center;
}

.DraftEditor-alignRight {
  text-align: right;
}

.dialog-freetext-question {
  padding-bottom: 1em !important;
}
.dialog-container {
  height: 100%;
  background-color: transparent;
  position: relative;
  margin-right: auto !important;
  margin-left: auto !important;
}

.dialog-header {
  padding: 0.3rem;
  margin-bottom: 21px;
  font-weight: 500;
  font-size: 2.2em;
  line-height: 1.2em;
  text-align: center;
}

.dialog-inner-container {
  margin-top: 0 !important;
  padding: 0 0 3em 0 !important;
}

.scrollbars-container > div {
  overflow: hidden !important;
  overflow-y: auto !important;
}

.scrollbars-container {
  max-height: 100%;
}

.dialog-title {
  margin: 0 0 0.5em 1em;
  font-size: 1.4em;
}

.dialog-line {
  box-shadow: 0px 2px 6px #D6D6D6;
  text-align: left;
  padding-top: 1em;
  max-width: 85%;
  position: relative;
}

.dialog-question {
  margin: 1.5rem 0.75rem 0.5rem;
  -webkit-border-radius: 35px;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius: 35px;
  -moz-border-radius-topleft: 0;
  border-radius: 35px;
  border-top-left-radius: 0;
  padding: 0.75rem 0.85rem 0;
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: #fff;
}

.dialog-answer {
  margin: 1.5rem 0.75rem 0.5rem auto;
  -webkit-border-radius: 35px;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius: 35px;
  -moz-border-radius-topright: 0;
  border-radius: 35px;
  border-top-right-radius: 0;
  padding: 0.75rem 0.85rem 0;
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: #fff;
}

.dialog-answer > p {
  float: right;
}

.single-dialog-container {
  margin: 1.3rem 1rem 0 0.15rem !important;
}

.dialog-answers-container {
  width: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
  
  margin: 0 !important;
  overflow: auto;
}
.dialog-answer-inner-container{
  width: 100%;
}
.picture-circle {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  box-shadow: 0 3px 6px #D6D6D6;
  display: inline-block;
}

.question-picture {
  background-image: url(/static/media/robotface.e2d81d3c.png);
  background-size: 45% 70%;
  background-repeat: no-repeat;
  background-position: center;
}

.answer-picture {
  background-image: url(/static/media/baseline-perm_identity-24px.b6bae80a.svg);
  background-size: 55% 55%;
  background-repeat: no-repeat;
  background-position: center;
}

.freetext-answer {
  width: 100%;
  height: 100px;
  overflow: auto;
  padding: 0.5rem;
}

.answer-button {
  background-color: #FFCC00;
  width: 100%;
  border: none;
  color: #FFFFFF;
  padding: 0.3rem;
}

.dialog-answer-button {
  border: 2px solid #FFCC00;
  background-color: #FFFFFF;
  width: 100%;
  line-height: 1.2 !important;
  padding: 1rem;
  margin-bottom: 0.3rem;
}

.dialog-answer-button:hover {
  opacity: 0.6;
}

.title-question {
  background-color: #f4faff;
}

.scroll-to-bottom-container {
  border: 3px solid #a0a0a0;
  border-radius: 50%;
  position: absolute;
  bottom: 3%;
  right: 3%;
  background-color: #fff;
  z-index: 1001;
  height: 55px;
  width: 55px;
  text-align: center;
}

.scroll-to-bottom {
  font-size: 3em;
  color: #a0a0a0;
  cursor: pointer;
}

.infoblock {
  position: relative;
  right: 1em;
  font-size: 2em;
  color: rgb(255, 204, 0);
  z-index: 99;
  float: right;
}

.infoModal {
  position: absolute;
   top: 45%;
   bottom: 0;
   left: 0;
   z-index: 10040;
   overflow: auto;
   overflow-y: auto;
   -webkit-justify-content: center!important;
           justify-content: center!important;
}

.dialogInfoBlock {
  position: absolute;
  right: -0.3em;
  top: -0.5em;
  color: rgb(255, 204, 0);
  z-index: 99;
  -webkit-animation: pulse 2s infinite;
          animation: pulse 2s infinite;
  box-shadow: 0 0 0 rgba(204,169,44, 0.4);
  cursor: pointer;
  border-radius: 50%;
  font-size: 1.5em;
}

.CalendarDay__today {
  background-color: aliceblue;
}

@media (max-width: 767px) {
  .single-dialog-container {
    margin: 0 !important;
  }
  
  .dialog-question {
    margin: 0.5rem 15px 0rem !important;
  }

  .single-dialog-container {
    margin-top: 1em !important;
  }

  .dialog-answer-button {
    padding: 0.5rem !important;
    line-height: 1.2em !important;
    font-size: 0.8em;
  }
  .picture-circle {
    width: 60px;
    height: 60px;
  }

  .dialog-line {
    line-height: 1.2em;
    font-size: 0.8rem;
    max-width: calc(100% - 90px);
  }

  .dialog-answer {
    margin: 0.5rem 15px 0rem auto !important;
  }

  .dialog-header {
    margin-bottom: 0.3em;
    font-size: 1.2em !important;
  }
}

@-webkit-keyframes blink {50% { color: transparent }}

@keyframes blink {50% { color: transparent }}
.loader__dot { -webkit-animation: 1s blink infinite; animation: 1s blink infinite }
.loader__dot:nth-child(2) { -webkit-animation-delay: 250ms; animation-delay: 250ms }
.loader__dot:nth-child(3) { -webkit-animation-delay: 500ms; animation-delay: 500ms }

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
.answer-button {
    background-color: #FFFFFF;
    width: 100%;
    border: solid #FFCC00;
    color: #000000;
    padding: 0.3rem;
    margin: 0 0 5px 0;
    line-height: 1.2em;
  }

  .survey-answer-button {
    padding: 1em !important;
    /* font-weight: 600 !important; */
    white-space: normal !important;
  }

  .survey-nav-row {
    position: relative;
    background-color: #fff;
    width: 100%;
    margin-top: 1em;
  }
  
  .survey-nav-ball {
    border-radius: 50%;
    height: 25px;
    width: 25px;
    display: inline-block;
    margin-right: 0.5rem;
    cursor: pointer;
    border: 1px solid;
  }

  .survey-container{
    height: 100%;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .DayPicker{
    margin: auto;
  }

  .answer-button:hover{
    opacity: 0.8;
  }
.modal-button {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s;
}

.modal-accept-button {
  background-color: rgb(181, 228, 19);
  border-color: rgb(181, 228, 19);
}

.modal-accept-button:hover {
  background-color: rgb(165, 208, 16);
  border-color: rgb(165, 208, 16);
}

.modal-new-button {
  background-color: #808080;
  border-color: #808080;
}

.modal-new-button:hover {
  background-color: #636363;
  border-color: #636363;
}
html, body, #root, .App, main {
  height: 100%;
  background-color: transparent !important;
  color:#535352;
}

body {
  font-family: 'Raleway', sans-serif !important;
}

