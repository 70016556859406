.dialog-container {
  height: 100%;
  background-color: transparent;
  position: relative;
  margin-right: auto !important;
  margin-left: auto !important;
}

.dialog-header {
  padding: 0.3rem;
  margin-bottom: 21px;
  font-weight: 500;
  font-size: 2.2em;
  line-height: 1.2em;
  text-align: center;
}

.dialog-inner-container {
  margin-top: 0 !important;
  padding: 0 0 3em 0 !important;
}

.scrollbars-container > div {
  overflow: hidden !important;
  overflow-y: auto !important;
}

.scrollbars-container {
  max-height: 100%;
}

.dialog-title {
  margin: 0 0 0.5em 1em;
  font-size: 1.4em;
}

.dialog-line {
  box-shadow: 0px 2px 6px #D6D6D6;
  text-align: left;
  padding-top: 1em;
  max-width: 85%;
  position: relative;
}

.dialog-question {
  margin: 1.5rem 0.75rem 0.5rem;
  -webkit-border-radius: 35px;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius: 35px;
  -moz-border-radius-topleft: 0;
  border-radius: 35px;
  border-top-left-radius: 0;
  padding: 0.75rem 0.85rem 0;
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: #fff;
}

.dialog-answer {
  margin: 1.5rem 0.75rem 0.5rem auto;
  -webkit-border-radius: 35px;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius: 35px;
  -moz-border-radius-topright: 0;
  border-radius: 35px;
  border-top-right-radius: 0;
  padding: 0.75rem 0.85rem 0;
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: #fff;
}

.dialog-answer > p {
  float: right;
}

.single-dialog-container {
  margin: 1.3rem 1rem 0 0.15rem !important;
}

.dialog-answers-container {
  width: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
  
  margin: 0 !important;
  overflow: auto;
}
.dialog-answer-inner-container{
  width: 100%;
}
.picture-circle {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  box-shadow: 0 3px 6px #D6D6D6;
  display: inline-block;
}

.question-picture {
  background-image: url('../../assets/images/robotface.png');
  background-size: 45% 70%;
  background-repeat: no-repeat;
  background-position: center;
}

.answer-picture {
  background-image: url('../../assets/images/baseline-perm_identity-24px.svg');
  background-size: 55% 55%;
  background-repeat: no-repeat;
  background-position: center;
}

.freetext-answer {
  width: 100%;
  height: 100px;
  overflow: auto;
  padding: 0.5rem;
}

.answer-button {
  background-color: #FFCC00;
  width: 100%;
  border: none;
  color: #FFFFFF;
  padding: 0.3rem;
}

.dialog-answer-button {
  border: 2px solid #FFCC00;
  background-color: #FFFFFF;
  width: 100%;
  line-height: 1.2 !important;
  padding: 1rem;
  margin-bottom: 0.3rem;
}

.dialog-answer-button:hover {
  opacity: 0.6;
}

.title-question {
  background-color: #f4faff;
}

.scroll-to-bottom-container {
  border: 3px solid #a0a0a0;
  border-radius: 50%;
  position: absolute;
  bottom: 3%;
  right: 3%;
  background-color: #fff;
  z-index: 1001;
  height: 55px;
  width: 55px;
  text-align: center;
}

.scroll-to-bottom {
  font-size: 3em;
  color: #a0a0a0;
  cursor: pointer;
}

.infoblock {
  position: relative;
  right: 1em;
  font-size: 2em;
  color: rgb(255, 204, 0);
  z-index: 99;
  float: right;
}

.infoModal {
  position: absolute;
   top: 45%;
   bottom: 0;
   left: 0;
   z-index: 10040;
   overflow: auto;
   overflow-y: auto;
   justify-content: center!important;
}

.dialogInfoBlock {
  position: absolute;
  right: -0.3em;
  top: -0.5em;
  color: rgb(255, 204, 0);
  z-index: 99;
  animation: pulse 2s infinite;
  box-shadow: 0 0 0 rgba(204,169,44, 0.4);
  cursor: pointer;
  border-radius: 50%;
  font-size: 1.5em;
}

.CalendarDay__today {
  background-color: aliceblue;
}

@media (max-width: 767px) {
  .single-dialog-container {
    margin: 0 !important;
  }
  
  .dialog-question {
    margin: 0.5rem 15px 0rem !important;
  }

  .single-dialog-container {
    margin-top: 1em !important;
  }

  .dialog-answer-button {
    padding: 0.5rem !important;
    line-height: 1.2em !important;
    font-size: 0.8em;
  }
  .picture-circle {
    width: 60px;
    height: 60px;
  }

  .dialog-line {
    line-height: 1.2em;
    font-size: 0.8rem;
    max-width: calc(100% - 90px);
  }

  .dialog-answer {
    margin: 0.5rem 15px 0rem auto !important;
  }

  .dialog-header {
    margin-bottom: 0.3em;
    font-size: 1.2em !important;
  }
}

@keyframes blink {50% { color: transparent }}
.loader__dot { animation: 1s blink infinite }
.loader__dot:nth-child(2) { animation-delay: 250ms }
.loader__dot:nth-child(3) { animation-delay: 500ms }

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}