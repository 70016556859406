.answer-button {
    background-color: #FFFFFF;
    width: 100%;
    border: solid #FFCC00;
    color: #000000;
    padding: 0.3rem;
    margin: 0 0 5px 0;
    line-height: 1.2em;
  }

  .survey-answer-button {
    padding: 1em !important;
    /* font-weight: 600 !important; */
    white-space: normal !important;
  }

  .survey-nav-row {
    position: relative;
    background-color: #fff;
    width: 100%;
    margin-top: 1em;
  }
  
  .survey-nav-ball {
    border-radius: 50%;
    height: 25px;
    width: 25px;
    display: inline-block;
    margin-right: 0.5rem;
    cursor: pointer;
    border: 1px solid;
  }

  .survey-container{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .DayPicker{
    margin: auto;
  }

  .answer-button:hover{
    opacity: 0.8;
  }