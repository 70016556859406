.modal-button {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s;
}

.modal-accept-button {
  background-color: rgb(181, 228, 19);
  border-color: rgb(181, 228, 19);
}

.modal-accept-button:hover {
  background-color: rgb(165, 208, 16);
  border-color: rgb(165, 208, 16);
}

.modal-new-button {
  background-color: #808080;
  border-color: #808080;
}

.modal-new-button:hover {
  background-color: #636363;
  border-color: #636363;
}